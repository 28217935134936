import apiResponse from '../api/ApiResponse';
import { OnboardingSurveyResponseStore } from '../api/OnboardingSurveyResponseStore';
import { SectionView } from '../views/DashboardView';
import actionResponse from './ActionResponse';

export class SaveSurveyResponsesAction {
  constructor(private readonly store: OnboardingSurveyResponseStore = new OnboardingSurveyResponseStore()) {}

  public async Execute(section: SectionView, groupId: number) {
    try {
      await apiResponse.handleApiResponse(() => this.store.Write(section, groupId));
      return actionResponse.Success('saved responses');
    } catch (err) {
      return actionResponse.HandleError(err);
    }
  }
}
