import { ActivityTrackingStore } from '@stores';
import { RecordActivityView } from '@views';
import ActionResponse from './ActionResponse';
import apiResponse from '@stores/ApiResponse';
import { useMutation } from '@tanstack/react-query';

export class AddActivityAction {
  constructor(private readonly store: ActivityTrackingStore = new ActivityTrackingStore()) {}

  public async Execute(activityView: RecordActivityView) {
    try {
      await apiResponse.handleApiResponse(() => this.store.write(activityView));
      return ActionResponse.Success('saved activity');
    } catch (e) {
      return ActionResponse.HandleError(e);
    }
  }
}

export const useAddActivityAction = () => {
  return useMutation({
    mutationFn: (activityView: RecordActivityView) => new AddActivityAction().Execute(activityView),
  });
};
